<template>
  <div>

    <BasicSkeleton
      v-if="isLoadingFiltersData"
      height="110px"
    />

    <CommissionsListsFilters
      v-else
      @on:filter="handleFilter"
      @on:create="$router.push({ name: 'new-commission' })"
    />
    <b-tabs
      v-model="selectedIndexTab"
      @input="selectedTab"
    >
      <b-tab
        title="Todos"
        lazy
      >
        <CommissionsGenericList ref="all" />
      </b-tab>
      <b-tab
        title="Pendiente"
        lazy
      >
        <CommissionsGenericList
          ref="toPay"
          status="Por pagar"
        />
      </b-tab>
      <b-tab
        title="Pagado"
        lazy
      >
        <CommissionsGenericList
          ref="paid"
          status="Pagado"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BTabs, BTab } from 'bootstrap-vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import CommissionsGenericList from '@/modules/trade/commissions/components/CommissionsGenericList.vue'
import CommissionsListsFilters from '@/modules/trade/commissions/components/CommissionsListsFilters.vue'

// Tipos de pestañas
const ALL = 0
const TO_PAY = 1
const PAID = 2

export default {
  components: {
    BTab,
    BTabs,
    BasicSkeleton,
    CommissionsGenericList,
    CommissionsListsFilters,
  },
  data() {
    return {
      /**
       * Indica si se está cargando la información de los filtros
       */
      isLoadingFiltersData: false,

      // Tipos de pestañas
      ALL,
      TO_PAY,
      PAID,
    }
  },
  computed: {
    ...mapGetters({
      /**
       * Filtros globales
       */
      getFilteredSelectedIndexTab: 'filters/getFilteredSelectedIndexTab',
    }),
    /**
     * Índice de la pestaña seleccionada
     */
    selectedIndexTab: {
      get() { return this.getFilteredSelectedIndexTab },
      set(value) { this.setFilteredSelectedIndexTab(value) },
    },
  },

  /**
   * Hook que se ejecuta al montar el componente
   * @summary Carga la información de los filtros
   */
  async mounted() {
    this.isLoadingFiltersData = true
    await this.loadSellersSuggests()
    await this.loadCommissionsYears()
    this.isLoadingFiltersData = false
  },

  methods: {
    ...mapActions({
      setFilteredStatus: 'filters/setFilteredStatus',
      loadSellersSuggests: 'commissions/loadSellersSuggests',
      loadCommissionsYears: 'commissions/loadCommissionsYears',
      setFilteredSelectedIndexTab: 'filters/setFilteredSelectedIndexTab',
    }),
    /**
     * Evento de filtrado
     * @summary En base a la pestaña seleccionada, carga la lista de comisiones
     */
    async handleFilter() {
      switch (this.selectedIndexTab) {
        case ALL:
          await this.$refs.all.loadCommissionsListWithVerification()
          break
        case TO_PAY:
          await this.$refs.toPay.loadCommissionsListWithVerification()
          break
        default:
          await this.$refs.paid.loadCommissionsListWithVerification()
          break
      }
    },

    /**
     * Evento de selección de pestaña
     * @summary Establece el índice seleccionado en el store y el estado de filtrado
     * @param {number} newTabIndex - Índice de la pestaña seleccionada
     */
    selectedTab(newTabIndex) {
      this.selectedIndexTab = newTabIndex
      switch (this.selectedIndexTab) {
        case ALL:
          this.setFilteredStatus('')
          break
        case TO_PAY:
          this.setFilteredStatus({ text: 'Por pagar' })
          break
        default:
          this.setFilteredStatus({ text: 'Pagado' })
          break
      }
    },
  },
}
</script>
