<template>
  <div>

    <!-- #region::title -->
    <b-form-row>
      <b-col cols="12">
        <h3>Comisiones</h3>
      </b-col>
    </b-form-row>
    <!-- #endregion::title -->

    <!-- #region::filters and action buttons -->
    <b-form-row>

      <b-col md="9">
        <b-form-row>

          <!-- #region::searcher -->
          <b-col
            md="4"
            class="my-50"
          >
            <BasicSearcher
              ref="basic-searcher"
              placeholder="Buscar"
              :callback="handleSearch"
            />
          </b-col>
          <!-- #endregion::searcher -->

          <!-- #region::Payment status filter -->
          <b-col
            cols="2"
            class="my-50"
          >
            <v-select
              v-model="filteredStatus"
              label="text"
              class="style-chooser"
              placeholder="Estatus de pago"
              :options="commissionsStatus"
              :disabled="canFilterByStatus"
              :clearable="selectedIndexTab === ALL"
            />
          </b-col>
          <!-- #endregion::Payment status filter -->

          <!-- #region::Payment date filter -->
          <b-col
            cols="3"
            class="my-50"
          >
            <b-form-datepicker
              v-model="filteredPaymentDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Fecha pago"
              local="es"
              reset-button
            />
          </b-col>
          <!-- #endregion::Payment date filter -->

          <!-- #region::Register date filter -->
          <b-col
            cols="3"
            class="my-50"
          >
            <b-form-datepicker
              v-model="filteredRegisterDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Fecha registro"
              local="es"
              reset-button
            />
          </b-col>
          <!-- #endregion::Register date filter -->

          <!-- #region::Seller searcher -->
          <b-col
            cols="2"
            class="my-50"
          >
            <vue-autosuggest
              v-model="sellerName"
              :suggestions="sellersOptions"
              :limit="5"
              :input-props="{
                id:'autosuggest_seller_searcher',
                class:'form-control',
                placeholder:'Buscar vendedor'
              }"
              @input="onSellerNameChange"
              @selected="filteredSeller = $event.item"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-col>
          <!-- #endregion::Seller searcher -->

          <!-- #region::Month picker -->
          <b-col
            md="2"
            class="my-50 mr-50"
          >
            <vue-monthly-picker
              v-model="filteredMonthAndYear"
              place-holder="Mes"
              date-format="MMMM yyyy"
              :month-labels="monthLabels"
              :input-class="['month-picker-style']"
              :min="minDate"
            />
          </b-col>
          <!-- #endregion::Month picker -->

          <!-- #region::Year filter -->
          <b-col
            cols="2"
            class="my-50"
          >
            <v-select
              v-model="filteredYear"
              label="year"
              class="style-chooser"
              placeholder="Año"
              :options="commissionsYears"
            />
          </b-col>
          <!-- #endregion::Year filter -->

        </b-form-row>
      </b-col>

      <!-- #region::Actions -->
      <b-col
        md="3"
        class="my-50"
      >
        <b-form-row>

          <b-col
            cols="12"
            md="auto"
            class="ml-auto"
          >

            <!-- #region::Download CSV File button -->
            <b-button
              variant="flat-success"
              class="csv-btn float-right"
              :disabled="isSendingEmail"
              @click="onSendingEmail"
            >
              <b-spinner
                v-if="isSendingEmail"
                class="mr-50"
                small
              />
              <feather-icon
                v-if="!isSendingEmail"
                icon="MailIcon"
                class="mr-50"
              />
              <span class="align-middle">Enviar reporte</span>
            </b-button>
            <!-- #endregion::Download CSV File button -->

          </b-col>

          <b-col
            cols="12"
            md="auto"
          >
            <b-button
              variant="secondary"
              @click="$emit('on:create')"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Nuevo recibo</span>
            </b-button>
          </b-col>

        </b-form-row>
      </b-col>
      <!-- #endregion::Actions -->

    </b-form-row>
    <!-- #endregion::filters and action buttons -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BFormRow, BButton, BFormDatepicker, BSpinner,
} from 'bootstrap-vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'

import vSelect from 'vue-select'
import getError from '@/helpers/ErrorsHandler'
import { VueAutosuggest } from 'vue-autosuggest'
import monthLabels from '@/helpers/MonthLabels'
import VueMonthlyPicker from 'vue-monthly-picker'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const DISABLED_STATUS = ['Por pagar', 'Pagado']
const ALL = 0

export default {
  components: {
    BCol,
    vSelect,
    BButton,
    BFormRow,
    BSpinner,
    BasicSearcher,
    VueAutosuggest,
    BFormDatepicker,
    VueMonthlyPicker,
  },
  data() {
    return {
      /**
       * For seller searcher
       */
      sellersOptions: [],

      monthLabels,
      /**
       * Set 2021 as min selectable date
       */
      minDate: this.$moment('2021-01-01'),

      isSendingEmail: false,
      DISABLED_STATUS,
      ALL,
    }
  },
  computed: {
    ...mapGetters({
      getSellersSuggests: 'commissions/getSellersSuggests',
      getCommissionStatus: 'commissions/getCommissionStatus',
      getCommissionsYears: 'commissions/getCommissionsYears',
      /**
       * Filtros globales
       */
      getFilteredYear: 'filters/getFilteredYear',
      getFilteredStatus: 'filters/getFilteredStatus',
      getFilteredSeller: 'filters/getFilteredSeller',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredPaymentDate: 'filters/getFilteredPaymentDate',
      getFilteredRegisterDate: 'filters/getFilteredRegisterDate',
      getFilteredMonthAndYear: 'filters/getFilteredMonthAndYear',
      getFilteredSelectedIndexTab: 'filters/getFilteredSelectedIndexTab',
    }),
    /**
     * Lista de vendedores sugeridos
     */
    sellersSuggests: {
      get() { return this.getSellersSuggests },
    },
    /**
     * Lista de estatus de comisiones
     */
    commissionsStatus: {
      get() { return this.getCommissionStatus },
    },
    /**
     * Lista de años de comisiones
     */
    commissionsYears: {
      get() { return this.getCommissionsYears },
    },
    /**
     * Filtro de año (filtro en store)
     */
    filteredYear: {
      get() { return this.getFilteredYear },
      set(value) { this.setFilteredYear(value) },
    },
    /**
     * Filtro de vendedor (filtro en store)
     */
    filteredSeller: {
      get() { return this.getFilteredSeller },
      set(value) { this.setFilteredSeller(value) },
    },
    /**
     * Filtro de estatus de comisión
     */
    filteredStatus: {
      get() { return this.getFilteredStatus },
      set(value) { this.setFilteredStatus(value) },
    },
    /**
     * Búsqueda global (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Filtro de fecha de pago (filtro en store)
     */
    filteredPaymentDate: {
      get() { return this.getFilteredPaymentDate },
      set(value) { this.setFilteredPaymentDate(value) },
    },
    /**
     * Filtro de fecha de registro (filtro en store)
     */
    filteredRegisterDate: {
      get() { return this.getFilteredRegisterDate },
      set(value) { this.setFilteredRegisterDate(value) },
    },
    /**
     * Filtro de mes y año (filtro en store)
     */
    filteredMonthAndYear: {
      get() { return this.getFilteredMonthAndYear },
      set(value) { this.setFilteredMonthAndYear(value) },
    },
    /**
     * Índice de la pestaña seleccionada
     */
    selectedIndexTab: {
      get() { return this.getFilteredSelectedIndexTab },
    },
    sellerName: {
      get() { return this.filteredSeller?.name || '' },
      set() {},
    },
    canFilterByStatus() {
      return this.DISABLED_STATUS.includes(this.filteredStatus?.text) && this.selectedIndexTab !== ALL
    },
  },

  mounted() {
    // Se agregan evento de escucha para cada uno de los filtros
    this.$watch('filteredYear', () => this.$emit('on:filter'))
    this.$watch('filteredSeller', () => this.$emit('on:filter'))
    this.$watch('filteredStatus', () => this.$emit('on:filter'))
    this.$watch('filteredSearchTerm', () => this.$emit('on:filter'))
    this.$watch('filteredPaymentDate', () => this.$emit('on:filter'))
    this.$watch('filteredRegisterDate', () => this.$emit('on:filter'))
    this.$watch('filteredMonthAndYear', () => this.$emit('on:filter'))
  },

  methods: {
    ...mapActions({
      sendCommissionsCSVFile: 'commissions/sendCommissionsCSVFile',
      /**
       * Filtros globales
       */
      setFilteredYear: 'filters/setFilteredYear',
      setFilteredSeller: 'filters/setFilteredSeller',
      setFilteredStatus: 'filters/setFilteredStatus',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredPaymentDate: 'filters/setFilteredPaymentDate',
      setFilteredRegisterDate: 'filters/setFilteredRegisterDate',
      setFilteredMonthAndYear: 'filters/setFilteredMonthAndYear',
    }),

    /**
     * Envía un correo electrónico con el reporte de comisiones
     */
    async onSendingEmail() {
      try {
        this.isSendingEmail = true
        await this.sendCommissionsCSVFile(this.loadParams())
        this.showToast(
          'Correo enviado',
          'El correo electrónico ha sido enviado correctamente.',
          'success',
        )
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isSendingEmail = false
      }
    },

    /**
    * Evento del buscador básico
    *
    * @summary Emite un evento con el término de búsqueda y limpia los campos de selección
    * @param {String} searchTerm - Término de búsqueda
    */
    handleSearch(searchTerm) {
      this.filteredYear = ''
      this.filteredSeller = ''

      if (!this.canFilterByStatus) {
        this.filteredStatus = ''
      }

      this.filteredMonthAndYear = ''
      this.filteredPaymentDate = null
      this.filteredRegisterDate = null

      this.filteredSearchTerm = searchTerm
      this.$emit('on:filter')
    },

    /**
    * Evento de cambio de nombre de vendedor
    * @summary Emite un evento cuando se ingresan caracteres en el buscador de vendedor
    * @param {String} text - Término de búsqueda
    */
    onSellerNameChange(text) {
      if (text === '' || text === undefined) {
        this.sellersOptions = []
        this.filteredSeller = null
        return
      }

      const filteredData = this.sellersSuggests
        .filter(item => `${item.Name} ${item?.LastName}`.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const namedSuggests = []

      filteredData.forEach(item => {
        namedSuggests.push({ name: `${item.Name}`, id: item.IdUser, email: item.email })
      })
      this.sellersOptions = [{
        data: namedSuggests,
      }]
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista. Determina
    * si se muestra solo pagos en efectivo
    */
    loadParams() {
      return {
        searchTerm: this.filteredSearchTerm,
        sellerTerm: this.filteredSeller?.id,
        paymentDateTerm: this.filteredPaymentDate,
        registerDateTerm: this.filteredRegisterDate,
        monthAndYearTerm: this.filteredMonthAndYear && this.filteredMonthAndYear.format('YYYY-MM-DD'),
        yearTerm: this.filteredYear?.year,

        statusTerm: this.filteredStatus?.text,
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import "@core/scss/vue/libs/vue-select.scss";

.style-chooser {
  background: #ffffff;
}
</style>
