<template>
  <div>
    <template v-if="isLoadingCommissions">
      <BasicSkeleton height="550px" />
    </template>

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <vue-good-table
            v-if="availableCommissions"
            id="commissions-table"
            ref="commissions-table"
            class="vgt-table-checkbox-styled"
            style-class="vgt-table condensed align-text-bottom"
            :columns="columns"
            :rows="commissions"
            :sort-options="{
              enabled: false,
            }"
          >

            <!-- #region::Empty response -->
            <div slot="emptystate">
              No hay comisiones para mostrar.
            </div>
            <!-- #endregion::Empty response -->

            <!-- #region::Column headers -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span>
                <h6 class="capitalize-col-hd">
                  <strong>{{ props.column.label }}</strong>
                </h6>
              </span>
            </template>
            <!-- #endregion::Column headers -->

            <!-- #region::Modifying rows cells -->
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- #region::ID cell -->
              <div
                v-if="props.column.field === 'commission_id'"
                style="font-size: 14px; margin-top: -1px;"
              >
                <router-link :to="{ name: 'commission-details', params: { id: props.row.commission_id } }">
                  {{ props.row.commission_id }}
                </router-link>
              </div>
              <!-- #endregion::ID cell -->

              <!-- #region:Refund status cell -->
              <div v-else-if="props.column.field === 'status'">
                <template v-if="props.row.status">
                  <div
                    :class="[styleBorder(props.row.status), styleColor(props.row.status)]"
                    role="alert"
                    class="text-nowrap"
                    style="display: inline; font-size: 11px;"
                  >
                    {{ props.row.status }}
                  </div>
                </template>
                <h6
                  v-else
                  class="text-nowrap"
                >Sin especificar</h6>
              </div>
              <!-- #endregion:Refund status cell -->

              <!-- #region::Payment options cell -->
              <div v-else-if="props.column.field === 'options'">
                <div class="d-flex justify-content-left">

                  <!-- #region::Preview image button -->
                  <b-button
                    v-b-tooltip.hover.top="'Ver recibo'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    :disabled="!props.row.url"
                    @click="$refs.imagePreviewModal.show(props.row.url)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <!-- #endregion::Preview image button -->

                  <!-- #region::Download receipt button -->
                  <b-button
                    v-b-tooltip.hover.top="'Descargar recibo'"
                    variant="flat-secondary"
                    class="btn-icon p-25"
                    :disabled="!props.row.url || !props.row.image_name"
                    @click="downloadVoucherImage({
                      commissionId: props.row.commission_id,
                      fileName: props.row.image_name
                    })"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                  <!-- #endregion::Download receipt button -->

                </div>
              </div>
              <!-- #endregion::Payment options cell -->

              <!-- #region::Custom general cell -->
              <div v-else>
                <h6 class="text-nowrap">
                  {{ props.formattedRow[props.column.field] }}
                </h6>
              </div>
              <!-- #endregion::Custom general cell -->

            </template>
            <!-- #endregion::Modifying rows cells -->

          </vue-good-table>

          <!-- #region::Alert when no commissions are available -->
          <b-alert
            v-if="!availableCommissions && !isLoadingCommissions"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron registros.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no commissions are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region::Pagination & items per list -->
    <BasicPaginator
      v-if="availableCommissions"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalCommission"
    />
    <!-- #endregion::Pagination & items per list -->

    <ImagePreviewModal
      ref="imagePreviewModal"
      :title="'Comprobante de pago'"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BAlert, VBTooltip, BButton,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import getError from '@/helpers/ErrorsHandler'
import validateCurrency from '@/helpers/CurrencyFormat'
import validatePercentage from '@/helpers/PercentageFormat'
import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ImagePreviewModal from '@/components/modals/ImagePreviewModal.vue'
import { statusBorderStyle, statusColorStyle } from '@/helpers/BannersStyles'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BCardText,
    VueGoodTable,
    BasicSkeleton,
    BasicPaginator,
    ImagePreviewModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lastPage: 0,
      perPage: 10,
      indexFrom: 0,
      currentPage: 1,
      totalCommission: 0,
      columns: [
        {
          label: 'No.',
          field: this.isValidRowNumber,
        },
        {
          label: 'ID',
          field: 'commission_id',
        },
        {
          label: 'Vendedor',
          field: 'seller',
        },
        {
          label: 'Total venta',
          field: 'total',
          formatFn: this.isValidCurrency,
        },
        {
          label: 'Porcentaje comisión',
          field: 'percentage',
          formatFn: this.isValidPercentage,
        },
        {
          label: 'Total comisión',
          field: 'total_commission',
          formatFn: this.isValidCurrency,
        },
        {
          label: 'Mes',
          field: 'month',
          formatFn: this.isValidMonthAndYear,
        },
        {
          label: 'Estatus',
          field: 'status',
          hidden: this.status !== '',
        },
        {
          label: 'Fecha registro',
          field: 'created_at',
          formatFn: this.isValidDate,
        },
        {
          label: 'Fecha pago',
          field: 'payment',
          formatFn: this.isValidDate,
        },
        {
          label: 'Comprobante',
          field: 'options',
        },
      ],
      commissions: [],
      isLoadingCommissions: false,
    }
  },
  computed: {
    ...mapGetters({
      /**
       * Filtros globales
       */
      getFilteredYear: 'filters/getFilteredYear',
      getFilteredStatus: 'filters/getFilteredStatus',
      getFilteredSeller: 'filters/getFilteredSeller',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredPaymentDate: 'filters/getFilteredPaymentDate',
      getFilteredRegisterDate: 'filters/getFilteredRegisterDate',
      getFilteredMonthAndYear: 'filters/getFilteredMonthAndYear',
    }),
    /**
     * Filtro de año (filtro en store)
     */
    filteredYear: {
      get() { return this.getFilteredYear },
    },
    /**
     * Filtro de estatus (filtro en store)
     */
    filteredStatus: {
      get() { return this.getFilteredStatus },
    },
    /**
     * Filtro de vendedor (filtro en store)
     */
    filteredSeller: {
      get() { return this.getFilteredSeller },
    },
    /**
     * Término de búsqueda (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
    },
    /**
     * Fecha de pago (filtro en store)
     */
    filteredPaymentDate: {
      get() { return this.getFilteredPaymentDate },
    },
    /**
     * Fecha de registro (filtro en store)
     */
    filteredRegisterDate: {
      get() { return this.getFilteredRegisterDate },
    },
    /**
     * Mes y año (filtro en store)
     */
    filteredMonthAndYear: {
      get() { return this.getFilteredMonthAndYear },
    },
    availableCommissions() {
      return this.totalCommission > 0
    },
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  *
  * @summary Carga la lista de pagos
  */
  async created() {
    await this.loadCommissionsListWithVerification()
  },

  methods: {
    ...mapActions({
      loadCommissions: 'commissions/loadCommissions',
      downloadVoucherImage: 'commissions/downloadVoucherImage',
    }),

    /**
    * Carga de elementos de la lista con verificación de paginado
    *
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadCommissionsListWithVerification() {
      await this.loadCommissionsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.currentPage = currentPage
      this.perPage = perPage
      this.loadCommissionsList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.commissions.length === 0) {
        this.currentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableCommissions) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadCommissionsList()
        }
      }
    },

    /**
    * Carga los registros de comisiones
    *
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de comisiones y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de comisiones
    */
    async loadCommissionsList() {
      try {
        this.isLoadingCommissions = true

        const response = await this.loadCommissions(this.loadParams())
        this.commissions = response.data.data.data
        this.totalCommission = response.data.data.total
        this.indexFrom = response.data.data.from
        this.lastPage = response.data.data.last_page
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isLoadingCommissions = false
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista. Determina
    * si se muestra solo pagos en efectivo
    */
    loadParams() {
      return {
        perPage: this.perPage,
        currentPage: this.currentPage,
        searchTerm: this.filteredSearchTerm,
        sellerTerm: this.filteredSeller?.id,
        paymentDateTerm: this.filteredPaymentDate,
        registerDateTerm: this.filteredRegisterDate,
        monthAndYearTerm: this.filteredMonthAndYear && this.filteredMonthAndYear.format('YYYY-MM-DD'),
        yearTerm: this.filteredYear?.year,

        statusTerm: this.status ? this.status : this.filteredStatus?.text,
      }
    },

    /**
    * Validacion de datos numéricos
    *
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },

    /**
     * Valida si la fecha es válida
     *
     * @summary Valida si la fecha es válida y se aplica el formato de fecha
     * @param {String} value - Valor a validar
     * @return {String} Valor con formato de fecha
     */
    isValidDate(value) {
      return value ? this.$moment(value).format('DD/MM/YYYY') : 'Sin especificar'
    },

    /**
     * Valida si la fecha es válida con formato de mes y año
     *
     * @summary Valida si la fecha es válida y se aplica el formato de fecha de mes y año
     * @param {String} value - Valor a validar
     * @return {String} Valor con formato de fecha de mes y año
     */
    isValidMonthAndYear(value) {
      return value
        ? this.$options.filters.capitalize(this.$moment(value).format('MMMM YYYY'))
        : 'Sin especificar'
    },

    /**
     * Valida si el porcentaje es válido
     *
     * @summary Valida si el porcentaje es válido y se aplica el formato de porcentaje
     * @param {String} value - Valor a validar
     * @return {String} Valor con formato de porcentaje
     */
    isValidPercentage(value) {
      return validatePercentage(value)
    },

    /**
     * Valida el número de fila
     *
     * @summary Valida si el número de fila es válido
     * @param {String} commission - Valor a validar
     * @return {String} Número de fila
     */
    isValidRowNumber(commission) {
      // Obtiene la posición del elemento en la lista de comisiones a partir del identificador
      const index = this.commissions.findIndex(
        item => item.commission_id === commission.commission_id,
      )
      return index + this.indexFrom
    },

    /**
    * Obtiene el estilo de border
    * @summary Obtiene el estilo de border de acuerdo al estatus de la orden
    * @param {String} status - Estatus de la orden
    * @return {String} Estilo de border
    */
    styleBorder(status) {
      return statusBorderStyle(status)
    },

    /**
    * Obtiene el estilo de color
    * @summary Obtiene el estilo de color de acuerdo al estatus de la orden
    * @param {String} status - Estatus de la orden
    * @return {String} Estilo de color
    */
    styleColor(status) {
      return statusColorStyle(status)
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
